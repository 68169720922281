<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item
          v-if="page_type == 0"
          label="模板名称："
          prop="name"
          :rules="[{ required: true, message: '请输入名称' }]"
        >
          <el-input
            placeholder="请输入名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="addition">
        <el-button @click="on_addition()" icon="el-icon-plus" type="primary"
          >添加新节点</el-button
        >
        <el-button @click="label_show = true" icon="el-icon-plus" type="primary"
          >选择风险点标签</el-button
        >

        <!-- <el-button
          @click="classify_show = true"
          icon="el-icon-plus"
          type="primary"
          >选择风险点分类</el-button
        > -->

        <el-button
          v-if="page_type != 0"
          @click="template_show = true"
          icon="el-icon-download"
          type="success"
          >导入模板</el-button
        >
      </div>

      <div class="overall">
        <div v-if="formLabelAlign.submitlabel_List.length != 0">
          <div class="title">风险点标签</div>
          <div>
            <el-tag
              v-for="(tag, index) in formLabelAlign.submitlabel_List"
              :key="index"
              style="margin: 0 5px 10px 0"
              closable
              @close="on_tabclose(index)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </div>

        <!-- <div v-if="formLabelAlign.submitlabel_List.length != 0"> -->
        <div class="title">选择风险点分类</div>
        <div>
          <el-tree
            :check-strictly="strictly"
            ref="tree"
            :data="power_Data"
            show-checkbox
            node-key="id"
            :props="{
              children: 'items',
              label: 'name',
            }"
          >
          </el-tree>
        </div>
        <!-- </div> -->
        <div v-if="formLabelAlign.inp_List.length != 0">
          <div class="title">新节点</div>
          <div v-for="(item, index) in formLabelAlign.inp_List" :key="index">
            <el-input placeholder="请输入内容" v-model="item.content" clearable>
              <template slot="append">
                <el-button
                  @click="on_deletenode(index)"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </template>
            </el-input>
            <div v-if="item.items.length != 0">
              <div v-for="(el, ins) in item.items" :key="ins" class="inputsty">
                <el-input
                  placeholder="请输入内容"
                  v-model="el.content"
                  clearable
                >
                  <template slot="append">
                    <el-button
                      @click="on_deletenode(el, item)"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                    ></el-button>
                  </template>
                </el-input>
              </div>
            </div>
            <div class="addition">
              <el-button
                @click="on_addition(item)"
                icon="el-icon-plus"
                type="success"
                >添加新子节点</el-button
              >
            </div>
          </div>
        </div>
      </div>

      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
    <!-- 风险源标签 -->
    <el-dialog
      width="80%"
      title="风险点标签"
      :visible.sync="label_show"
      show-close
    >
      <div class="from_submit">
        <el-form label-width="120px" :model="label_from" class="from">
          <el-form-item label="风险点名称：">
            <el-input
              placeholder="请输入风险点名称"
              size="mini"
              class="pop-inp"
              v-model="label_from.name"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="search_button">
          <div>
            <el-button
              @click="way_labelList(true)"
              type="primary"
              icon="el-icon-search"
              >查询</el-button
            >
          </div>
        </div>
      </div>
      <el-table
        ref="labeltable"
        highlight-current-row
        height="300px"
        :data="label_List"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="name" label="标签名"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              class="list_sty"
              type="primary"
              @click="on_addlabel(scope.row)"
              >添加</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="on_change"
          :current-page="label_from.page"
          :page-size="label_from.page_size"
          :total="label_total"
        >
        </el-pagination>
      </div>
    </el-dialog>

    <!-- 风险源分类 -->
    <!-- <el-dialog width="50%" title="风险点分类" :visible.sync="classify_show">
      <div class="from_submit">
        <el-tree
          @check="on_check"
          :check-strictly="strictly"
          ref="tree"
          :data="power_Data"
          show-checkbox
          node-key="id"
          :props="{
            children: 'items',
            label: 'name',
          }"
        >
        </el-tree>
      </div>
    </el-dialog> -->

    <!-- 模板列表 -->
    <el-dialog
      width="80%"
      title="模板列表"
      :visible.sync="template_show"
      show-close
    >
      <div class="from_submit">
        <el-form label-width="120px" :model="template_from" class="from">
          <el-form-item label="模板名称：">
            <el-input
              placeholder="请输入模板名称"
              size="mini"
              class="pop-inp"
              v-model="template_from.name"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="search_button">
          <div>
            <el-button
              @click="way_templateList(true)"
              type="primary"
              icon="el-icon-search"
              >查询</el-button
            >
          </div>
        </div>
      </div>
      <el-table
        ref="labeltable"
        highlight-current-row
        height="300px"
        :data="template_List"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="name" label="模板名称"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              class="list_sty"
              type="primary"
              @click="on_addtemplate(scope.row)"
              >引入</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="on_templatechange"
          :current-page="template_from.page"
          :page-size="template_from.page_size"
          :total="template_total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  insptemplatecontentSave,
  insptemplatesaveInfo,
  inspectionsaveInfo,
  tagsindex,
  insptemplateindex,
  inspectioncontentSave,
  Dangercateindex,
  spectioncontentSave,
  spectionsaveInfo,
} from "../../assets/request/api";

export default {
  name: "Special_templatealter",
  data() {
    return {
      //当前页面类型  0模板  1 专项检查 2随机督察
      page_type: 0,
      //请求信息接口
      modification_URL: "",
      //保存信息接口
      save_URL: "",
      //进入请求类型
      request_type: 0,
      type_name: "保存",
      //要修改的id
      id: "",
      formLabelAlign: {
        name: "",
        inp_List: [],
        submitlabel_List: [],
      },
      //标签所需参数和列表
      label_List: [],
      label_from: {
        name: "",
        is_enable: "1",
        page_size: 10,
        page: 1,
      },
      label_total: 0,
      label_show: false,

      //模板所需参数和列表
      template_List: [],
      template_from: {
        name: "",
        is_enable: "1",
        page_size: 10,
        page: 1,
      },
      template_total: 0,
      template_show: false,
      //风险分类
      classify_show: false,
      strictly: true,
      power_Data: [],
      roleIds: [],
    };
  },
  created() {
    let { id, page_type } = this.$route.query;
    this.page_type = page_type;
    let modification_URL = "";
    let save_URL = "";
    switch (page_type) {
      case "0":
        modification_URL = insptemplatesaveInfo;
        save_URL = insptemplatecontentSave;
        break;
      case "1":
        modification_URL = inspectionsaveInfo;
        save_URL = inspectioncontentSave;
        break;
      case "2":
        modification_URL = spectionsaveInfo;
        save_URL = spectioncontentSave;
    }
    this.modification_URL = modification_URL;
    this.save_URL = save_URL;

    this.way_show(id);

    this.way_labelList();
    this.way_templateList();
    this.way_classify();
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      console.log(this.modification_URL);
      this.modification_URL(froms).then((res) => {
        console.log(res);
        let { info, status_list } = res.data;
        this.status_list = status_list;
        if (id) {
          let { formLabelAlign } = this;
          this.id = id;
          this.type_name = "修改";
          let { name, content, cate_ids } = info;
          if (cate_ids) {
            this.$refs.tree.setCheckedKeys(cate_ids.split(","));
          }

          formLabelAlign.name = name;
          if (content) {
            this.way_echo(content);
          }
        }
      });
    },
    //风险源分类
    way_classify() {
      Dangercateindex().then((res) => {
        this.power_Data = res.data.data;
      });
    },

    //回显或者添加节点
    way_echo(content) {
      let content_List = Object.values(content);
      let { formLabelAlign } = this;
      content_List.map((item) => {
        console.log(item);
        let items_Lsit = [];
        if (item.itmes) {
          item.itmes.map((el) => {
            items_Lsit.push({
              type: 1,
              content: el.content,
            });
          });
        }
        formLabelAlign.inp_List.push({
          type: 1,
          content: item.info.content,
          items: [...items_Lsit],
        });
      });
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id, page_type, power_Data } = this;

          console.log(power_Data);
          let { inp_List, submitlabel_List } = formLabelAlign;
          let From = {
            id: 0,
          };
          if (id) From.id = id;

          From.basea = {
            tag_ids: submitlabel_List.map((item) => item.id).join(","),
            // cate_ids: this.roleIds.join(","),
            cate_ids: this.$refs.tree.getCheckedKeys().join(","),
            tag_arr: submitlabel_List.map((item) => {
              return { id: item.id, name: item.name };
            }),
          };

          if (page_type == 0) {
            From.basea.name = formLabelAlign.name;
          }
          From.info = [];
          From.info.push(...inp_List);

          this.save_URL(From).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.$router.go(-1);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加节点
    on_addition(item) {
      if (item) {
        item.items.push({
          type: 1,
          content: "",
        });
        return;
      }
      this.formLabelAlign.inp_List.push({
        type: 1,
        content: "",
        items: [],
      });
    },
    //删除节点  删除类型  删除的下标
    on_deletenode(index, item) {
      if (item) {
        item.items.splice(index, 1);
        return;
      }
      this.formLabelAlign.inp_List.splice(index, 1);
    },
    //模板信息列表
    way_templateList(type) {
      if (type) {
        this.template_from.page = 1;
      }
      let { template_from } = this;
      insptemplateindex(template_from).then((res) => {
        this.template_total = res.data.total;
        this.template_List = res.data.data;
      });
    },
    on_templatechange(val) {
      this.template_from.page = val;
      this.way_templateList();
    },

    on_addtemplate(row) {
      let { id } = row;
      insptemplatesaveInfo({ id: id }).then((res) => {
        if (res.err_code == 0) {
          let { content } = res.data.info;
          this.way_echo(content);
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.template_show = false;
        }
      });
    },

    //风险源标签
    way_labelList(type) {
      if (type) {
        this.label_from.page = 1;
      }
      let { label_from } = this;
      tagsindex(label_from).then((res) => {
        this.label_total = res.data.total;
        this.label_List = res.data.data;
      });
    },
    on_change(val) {
      this.label_from.page = val;
      this.way_labelList();
    },
    //添加标签
    on_addlabel(row) {
      console.log(row);
      let { submitlabel_List } = this.formLabelAlign;
      let is_join = submitlabel_List.some((item) => {
        return item.id == row.id;
      });
      if (is_join) {
        this.$message({
          message: "当前风险源标签已添加，请勿重复添加",
          type: "warning",
        });
      } else {
        submitlabel_List.push(row);
        this.$message({
          message: "添加风险源标签成功",
          type: "success",
        });
      }
    },
    //删除标签
    on_tabclose(index) {
      this.formLabelAlign.submitlabel_List.splice(index, 1);
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.overall {
  width: 90%;
  margin: 0 auto;
  .inputsty {
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
  }
}
.addition {
  margin: 10px 0;
  text-align: center;
}
.title {
  font-size: 16px;
  margin: 10px 0;
}
</style>